<template>
  <div class="white_box ">
    <div class="box_inner">
      <div class="box_head d-flex align-items-center">
        <div class="w-100 left flex justify-content-between align-items-center">
          <h2>Most Recent Links</h2>
          <RouterLink to="/links/">View all <i class="fal fa-arrow-right"></i></RouterLink>
        </div>
      </div>
      <div class="box_content links_list">
        <template v-if="getLinksLoaders.retrieve">
          <div class="d-flex h-100 justify-content-center align-items-center">
            <clip-loader color="#3988FE"></clip-loader>
          </div>
        </template>
        <template v-if="getLinks.items && getLinks.items.length">
          <div  v-for="(link, index) in getLinks.items" :class="{'mb-2': index != getLinks.items.length -1 }" class="w-100 p-2 rounded-lg bg-white flex justify-content-between align-items-center">
            <div class="flex justify-content-center align-items-center">
              <div class="d-flex align-items-start mx-2 profile__box">
                <div class="avatar">
                  <div v-if="!link.information || !link.information.favicon_url || link.information.favicon_url=='data:,'"
                       style="font-size: 17px;" class="fal fa-globe"></div>
                  <div v-else-if="link.information && link.information.favicon_url"
                       class="brand_img favicon_img"
                       :style="brandImageLink(link.information.favicon_url)"></div>
                </div>
              </div>
              <div>
                <template v-if="link.status">
                  <a class="word_break_all link font-weight-bold " style="" target="_blank"
                     :href="getDomainURl(link)">{{ getDomainURl(link) }}</a>
                </template>
                <template v-else>
                  <a class="word_break_all link"
                     v-if="getSiteDetails.isWhiteLabel && getSiteDetails.custom_domain_name"
                     target="_blank"
                     :href="getSiteDetails.custom_domain_name+link.shorten_url">
                    {{ getSiteDetails.custom_domain_name }}{{ link.shorten_url }}
                  </a>
                  <a class="word_break_all link" target="_blank"
                     :href="getDomainURl(link)">{{ getDomainURl(link) }}</a>
                </template>
                <i
                  v-tooltip="'Copy Link'"
                  v-clipboard:copy="getDomainURl(link)"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  class="fal fa-clipboard copy_link_dashboard ml-2 copy_link"></i>
                <div class="url flex align-items-center created_date table__name">
                  <p class="mr-1">{{ link.created_at | relativeCaptilize }} - </p>
                  <div class="content">
                    <div class="clearfix"></div>
                    <div class="url" v-tooltip="link.url">
                      {{ limitTextLength(link.url, 25) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex align-items-center">
              <div class="visitors-badge" v-if="!link.fetchStats">
                <i class="fa fa-line-chart" aria-hidden="true"></i>
                {{ commaSeparatedNumber(link.total_visitors) }} Clicks
              </div>
              <skeleton v-else></skeleton>

              <b-dropdown ref="dropdownActionMenu" right offset="10"
                          class="d-inline-block table_action_dropdown mx-3 dropdown-menu-right default_style_dropdown"
                          :no-caret="true">
                <!--d-flex align-items-center-->
                <div class=" dropdown_header no_arrow text-right" slot="button-content">
                  <span class="text"><i class="fal fa-ellipsis-v fw-500"></i></span>
                </div>
                <ul class="clear inner ">
                  <li class="list_item">
                    <a @click.prevent="linkDetailView(link.cta.brand, link.cta, link)" v-tooltip="'View Stats'" class="text" data-cy="link-stats">
                      <i class="far fa-line-chart"></i> View Stats
                    </a>
                  </li>
                  <li class="list_item">
                    <a
                      v-clipboard:copy="getDomainURl(link)"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      href="javascript:;" data-cy="link-copy">
                      <i class="fal  fa-clipboard" aria-hidden="true"></i>
                      Copy Link
                    </a>
                  </li>
                  <li class="list_item">
                    <template v-if="link.qr_code_url">
                      <a @click.prevent="renderQRCode(link.qr_code_url)"
                         href="javascript:;">
                        <i class="far fa-qrcode" aria-hidden="true"></i>

                        Download QR Code
                      </a>
                    </template>
                    <template v-else>
                      <a
                        @click.prevent="createQRCode({url: getDomainURl(link), slug: link.shorten_url})"
                        href="javascript:;" data-cy="link-qr">
                        <i class="far fa-qrcode" aria-hidden="true"></i>
                        Download QR Code
                      </a>
                    </template>
                  </li>
                  <li class="list_item" v-if="link.policy.edit">
                    <a @click.prevent="editLink(link)"
                       target="_blank" href="" data-cy="link-edit">
                      <i class="far  fa-pencil"
                         aria-hidden="true"></i> Edit
                    </a>
                  </li>
                  <li class="list_item" v-if="link.policy.delete">
                    <a @click.prevent="deleteLink({linkId: link._id,index:index})"
                       target="_blank" href="" data-cy="link-remove">
                      <i class="far  fa-trash"
                         aria-hidden="true"></i> Remove
                    </a>
                  </li>
                </ul>

              </b-dropdown>
            </div>
          </div>
        </template>
        <template v-else>
            <p class="no-rows">
              <i class="fab fa-bandcamp" aria-hidden="true"></i>No results found.
            </p>
        </template>
       </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { linkTypes } from '@/state/modules/mutation-types'

export default {
  async created () {
    this.getLinks.limit = 3
    await this.fetchLinks()
    await this.fetchLinkAnalytics()
    this.getLinks.limit = 10
  },
  computed: {
    ...mapGetters(['getLinks', 'getLinksLoaders'])
  },
  methods: {
    ...mapActions(['fetchLinks', 'fetchLinkAnalytics', 'renderQRCode', 'createQRCode', 'deleteLink']),
    /**
     * Function to get the complete URL for a given link.
     * @param link
     * @returns {*}
     */
    getDomainURl (link) {
      return link.domain ? link.domain.url+link.shorten_url : link.domain.url+link.shorten_url
    },
    /**
     * Function to edit a link's information and navigate to the link page.
     * @param value
     */
    editLink (value) {
      if (!value['tags'] || value['tags'] == null) {
        value['tags'] = []
      }
      this.$store.commit(linkTypes.SET_LINKS_NEW_CREATED, false)
      this.$store.commit(linkTypes.SET_LINKS_DEFAULT_VALUE)
      this.$store.commit(linkTypes.SET_LINKS_EDIT_VALUE, value)
      this.$router.push({ 'name': 'link', query: { 'shorten_url': value.shorten_url } })
    },
  }
}
</script>
<style scoped>
.link {
  color: #3988FE;
}
.copy_link_dashboard {
  background: #F3F4F6;
  padding: 0.4rem 0.55rem;
  border-radius: 100%;
  cursor: pointer;
}
.visitors-badge {
  background: #F3F4F6;
  border-radius: 0.2rem;
  padding: 0.2rem 0.5rem;
  color: #8A8E86;
  font-size: 0.7rem;
}
.links_list {
  min-height: 14rem;
}
</style>
